<template lang="pug">
.main-content.layout-main__centering.container
  .page-partnership
    .page
      h1.page-title Finex.market
      .page-text
        | Приглашайте интересующихся криптовалютами людей на платформу Finex.market и зарабатывайте на их сделках!
      .page-text
        | Наша реферальная программа Finex.market имеет 1 уровень и возвращает вам 100% с комиссий реферала.
        | Со всеми комиссиями мейкера и тейкера вы можете ознакомиться в разделе
        router-link.comissions__text-link(:to="{ name: 'Commissions' }", tag="span")  "Комиссии и лимиты".
      .page-text
        | Через 180 дней проценты отчислений будут снижены до 50%, а через 360 дней они составят 10%.
        | Как видите, первые полгода самые щедрые.
      .page-text
        | Получать дивиденды с оборота своих рефералов вы будете пожизненно. Поэтому есть смысл приглашать на Finex.market как можно больше людей,
        | даже если они сейчас не интересуются криптовалютой — в будущем это обязательно случится, а вы всегда будете иметь на своем кошельке криптозаначку.
      .page-text
        | Если же вы сможете привлечь парочку «крипто-китов», то пассивный доход с их сделок будет радовать каждый день. И это навсегда.
      .page-text
        | Просмотреть свои дивиденды и скопировать индивидуальную реферальную ссылку вы можете в личном кабинете.
</template>


<script>
export default {
}
</script>

<style lang="scss" scoped>
.page-partnership {
  padding-bottom: 100px;
}

.page-title {
  font-size: 35px;
}

.page-text {
  font-size: 18px;
  padding-bottom: 20px;
  line-height: 20px;
}

.comissions__text-link{
  cursor: pointer;
  font-weight: bold;
  color: #393939;
}

.comissions__text-link:hover{
  text-decoration: underline;
}
</style>
